import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@angular/material/progress-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
const _c0 = ["*"];
const _c1 = (a0, a1) => ({
  "wlcm-button--loading": a0,
  "wlcm-button--disabled": a1
});
function WlcmButtonComponent_mat_spinner_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "mat-spinner", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("color", ctx_r0.type === "primary" ? "accent" : "primary")("diameter", 16);
  }
}
class WlcmButtonComponent {
  constructor() {
    this.type = 'primary';
    this.size = 'large';
    this.isDisabled = false;
    this.isLoading = false;
    this.clicked = new EventEmitter();
  }
  onClick() {
    if (this.isDisabled || this.isLoading) return;
    this.clicked.emit();
  }
  static {
    this.ɵfac = function WlcmButtonComponent_Factory(t) {
      return new (t || WlcmButtonComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WlcmButtonComponent,
      selectors: [["wlcm-button"]],
      inputs: {
        type: "type",
        size: "size",
        isDisabled: "isDisabled",
        isLoading: "isLoading"
      },
      outputs: {
        clicked: "clicked"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 4,
      vars: 9,
      consts: [[3, "click", "ngClass"], [1, "wlcm-button__content"], [3, "color", "diameter", 4, "ngIf"], [3, "color", "diameter"]],
      template: function WlcmButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "button", 0);
          i0.ɵɵlistener("click", function WlcmButtonComponent_Template_button_click_0_listener() {
            return ctx.onClick();
          });
          i0.ɵɵelementStart(1, "div", 1);
          i0.ɵɵtemplate(2, WlcmButtonComponent_mat_spinner_2_Template, 1, 2, "mat-spinner", 2);
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate2("wlcm-button wlcm-button--", ctx.type, " wlcm-button--", ctx.size, "");
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(6, _c1, ctx.isLoading, ctx.isDisabled));
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.isLoading);
        }
      },
      dependencies: [CommonModule, i1.NgClass, i1.NgIf, MatProgressSpinnerModule, i2.MatProgressSpinner],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmButtonComponent, [{
    type: Component,
    args: [{
      selector: 'wlcm-button',
      standalone: true,
      imports: [CommonModule, MatProgressSpinnerModule],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<button\n  (click)=\"onClick()\"\n  class=\"wlcm-button wlcm-button--{{ type }} wlcm-button--{{ size }}\"\n  [ngClass]=\"{\n    'wlcm-button--loading': isLoading,\n    'wlcm-button--disabled': isDisabled\n  }\"\n>\n  <div class=\"wlcm-button__content\">\n    <mat-spinner\n      *ngIf=\"isLoading\"\n      [color]=\"type === 'primary' ? 'accent' : 'primary'\"\n      [diameter]=\"16\"\n    ></mat-spinner>\n\n    <ng-content></ng-content>\n  </div>\n</button>\n"
    }]
  }], null, {
    type: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    isDisabled: [{
      type: Input
    }],
    isLoading: [{
      type: Input
    }],
    clicked: [{
      type: Output
    }]
  });
})();
class WlcmButtonModule {
  static {
    this.ɵfac = function WlcmButtonModule_Factory(t) {
      return new (t || WlcmButtonModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: WlcmButtonModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [WlcmButtonComponent]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WlcmButtonModule, [{
    type: NgModule,
    args: [{
      imports: [WlcmButtonComponent],
      exports: [WlcmButtonComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { WlcmButtonComponent, WlcmButtonModule };
